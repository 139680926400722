exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-carrito-tsx": () => import("./../../../src/pages/carrito.tsx" /* webpackChunkName: "component---src-pages-carrito-tsx" */),
  "component---src-pages-cuidado-capilar-tsx": () => import("./../../../src/pages/cuidado-capilar.tsx" /* webpackChunkName: "component---src-pages-cuidado-capilar-tsx" */),
  "component---src-pages-detalle-[id]-tsx": () => import("./../../../src/pages/detalle/[id].tsx" /* webpackChunkName: "component---src-pages-detalle-[id]-tsx" */),
  "component---src-pages-firmas-tsx": () => import("./../../../src/pages/firmas.tsx" /* webpackChunkName: "component---src-pages-firmas-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-maquillaje-tsx": () => import("./../../../src/pages/maquillaje.tsx" /* webpackChunkName: "component---src-pages-maquillaje-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-productos-tsx": () => import("./../../../src/pages/productos.tsx" /* webpackChunkName: "component---src-pages-productos-tsx" */),
  "component---src-pages-rastrear-pedido-tsx": () => import("./../../../src/pages/rastrear-pedido.tsx" /* webpackChunkName: "component---src-pages-rastrear-pedido-tsx" */),
  "component---src-pages-skincare-tsx": () => import("./../../../src/pages/skincare.tsx" /* webpackChunkName: "component---src-pages-skincare-tsx" */)
}

